<template lang="">
  <div>
    <b-card>
      <basetable
        :dataurl="dataurl"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
        :is-edit="false"
        :is-delete="false"
      />
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTable.vue'
import {
  BCard
} from 'bootstrap-vue'

export default {
  components: {
    basetable,
    BCard
  },
  data() {
    return {
      dataurl:"/tire-axle",
      baseroute:"operasional-tire-axle",
      title:"Poros Ban Kendaraan",
      fields: [
        { key: 'name', label: 'Nama Konfigurasi', sortable: true },
        // { key: 'code', label: 'Kode', sortable: true },
        { key: 'amount', label: 'Jumlah Ban', sortable: true }
      ],
    }
  },
}
</script>
<style lang="">

</style>
